export function getSvgIcon({ name, clean = name.trim().toLowerCase() }) {
  switch (clean) {
    case 'graphql':
      return require(`../assets/icons/graphql.svg`)
    case 'javascript':
      return require(`../assets/icons/javascript.svg`)
    case 'react.js':
      return require(`../assets/icons/reactjs.svg`)
    default:
      return
  }
}
