import React from 'react'
import { graphql } from 'gatsby'
import {
  Card,
  BlogPostHeroWrapper,
  MinimalLayout,
  AboutAuthorCard,
  HeroImage,
} from '../components'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { COLORS } from '../utils'
import { getSvgIcon } from '../utils/helpers'
import differenceInDays from 'date-fns/differenceInDays'

const THIRTY_DAYS = 30

const Title = styled.h1`
  text-align: center;
  color: ${COLORS.white};
  max-width: 800px;
  margin: 0 auto;
  @media (max-width: 400px) {
    font-size: 1.5em;
  }
`

const Flag = styled.h6`
  position: relative;
  top: 0.5rem;
  left: -2rem;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  background-color: ${COLORS.brightPink};
  color: white;
  display: inline-block;
  box-shadow: 18px 4px 10px 0px rgba(50, 50, 50, 0.25);
  &::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 34px 34px 0 0;
    border-color: ${COLORS.brightPink} transparent transparent transparent;
    position: absolute;
    top: 0;
    right: -33px;
  }
  &::before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 34px 0 0 34px;
    border-color: transparent transparent transparent ${COLORS.brightPink};
    position: absolute;
    bottom: 0;
    right: -33px;
  }
`

const FlagFold = styled.span`
  display: inline-block;
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 8px 44px 0px;
  border-color: transparent #f5134c transparent transparent;
  bottom: 0px;
  left: -8px;

  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0px 0 8px 8px;
    border-color: transparent transparent #b30732 transparent;
    top: -7px;
    left: 0px;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(48, 51, 107, 0.75);
  z-index: 1;
  border-radius: 40px;
`

const MainHeroContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  padding: 2.5rem 2rem;
`

const ReadTime = styled.p`
  margin: 0;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  color: ${COLORS.white};
  padding: 0.25rem 1rem;
  font-weight: 700;
  border-radius: 10px;
  font-size: 0.75rem;
  &::before {
    content: '⏱';
    margin-right: 0.5rem;
  }
`

const TopicIcon = styled.img`
  height: 3rem;
  width: 3rem;
  position: absolute;
  bottom: -0.5rem;
  right: 1.5rem;
  display: block;
`

const NewTag = styled.span`
  position: absolute;
  right: -30px;
  top: 30px;
  padding: 10px 0px;
  font-weight: 700;
  border-radius: 0px 6px 6px 0;
  letter-spacing: 1px;
  background-color: #ffd32a;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: ${COLORS.black};
  box-shadow: 4px 4px 7px #dfdde7, -4px -4px 7px #edebf5;
  
  &::after {
    content: 'new';
  }

  @media (max-width: 1100px) {
    top: unset;
    bottom: -30px;
    writing-mode: unset;
    text-orientation: unset;
    right: 60px;
    padding: 0px 10px;
    border-radius: 0px 0px 6px 6px;
  }
`

export default function Template({ data }) {
  const { markdownRemark, site } = data // data.markdownRemark holds our post data
  const { frontmatter, html, fields, excerpt } = markdownRemark
  const { hero } = frontmatter

  const image = `${site.siteMetadata.siteUrl}${hero.image}`
  const iconSrc = getSvgIcon({
    name: frontmatter.category,
  })

  const isNew =
    differenceInDays(new Date(), new Date(frontmatter.date)) <= THIRTY_DAYS

  return (
    <MinimalLayout>
      <Helmet>
        <title>
          {site.siteMetadata.title} | {frontmatter.title}
        </title>
        {/* OpenGL MetaData */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={frontmatter.title} />
        <meta property="og:description" content={excerpt} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={site.siteMetadata.siteUrl} />
        {/* Twitter Metadata */}
        <meta name="twitter:title" content={frontmatter.title} />
        <meta name="twitter:description" content={excerpt} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div>
        <BlogPostHeroWrapper>
          {isNew && <NewTag aria-label="new blog post" />}
          <Overlay />
          <HeroImage image={hero.image} />
          <MainHeroContent>
            <Flag>
              <FlagFold />
              {frontmatter.date}
            </Flag>
            <Title>{frontmatter.title}</Title>
            {iconSrc && <TopicIcon src={iconSrc} alt={frontmatter.category} />}
            <ReadTime>{fields.readingTime.text}</ReadTime>
          </MainHeroContent>
        </BlogPostHeroWrapper>
        <Card>
          <article>
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </article>
          <hr />
          <AboutAuthorCard
            name={site.siteMetadata.title}
            avatarUrl={site.siteMetadata.avatarUrl}
            socialMedia={site.siteMetadata.socialMedia}
          />
        </Card>
      </div>
    </MinimalLayout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        avatarUrl
        socialMedia {
          twitterURL
          linkedinURL
          githubURL
          devToURL
        }
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(pruneLength: 250)
      frontmatter {
        category
        date(formatString: "MMMM DD, YYYY")
        path
        title
        hero {
          image
          alt
        }
      }
      fields {
        readingTime {
          text
        }
      }
    }
  }
`
